// import AR from './AR';
// import AT from './AT';
// import AU from './AU';
// import BE from './BE';
// import CA from './CA';
// import CH from './CH';
// import CZ from './CZ';
import DE from './DE';
// import DK from './DK';
// import ES from './ES';
// import FI from './FI';
// import FR from './FR';
import GB from './GB';
// import GR from './GR';
// import HU from './HU';
// import IE from './IE';
// import IT from './IT';
// import LT from './LT';
// import NL from './NL';
// import PL from './PL';
// import RU from './RU';
// import SE from './SE';
// import TR from './TR';
import US from './US';
import type { IconMap } from '@quno/patient-journey/src/components/ui/Icon';

export const Countries: IconMap = {
  // AR,
  // AT,
  // AU,
  // BE,
  // CA,
  // CH,
  // CZ,
  DE,
  // DK,
  // ES,
  // FI,
  // FR,
  GB,
  // GR,
  // IE,
  // IT,
  // HU,
  // LT,
  // NL,
  // PL,
  // RU,
  // SE,
  // TR,
  US,
};
