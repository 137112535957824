import Arrow from './Arrow';
import Angle from './Angle';
import Email from './Email';
import Phone from './Phone';
import Menu from './Menu';
import Cross from './Cross';
import Star from './Star';
// import PhoneLines from './PhoneLines';
import Globe from './Globe';
// import Whatsapp from './Whatsapp';
// import Facebook from './Facebook';
// import Instagram from './Instagram';
// import LinkedIn from './LinkedIn';
// import Twitter from './Twitter';
// import Youtube from './Youtube';
import Xmark from './Xmark';
// import Verified from './Verified';
import { Countries } from './Countries';
import { Payment } from './Payment';
import ArrowShort from './ArrowShort';
// import Qunomedical from './Qunomedical';
// import Messenger from './Messenger';
// import Heart from './Heart';
import Check from './Check';
// import RoundCheck from './RoundCheck';
import LocationPin from './LocationPin';
// import Filters from './Filters';
import USUKFlag from './USUKFlag';
// import Free from './Free';
import Stethoscope from './Stethoscope';
// import SatisfactionGuarantee from './SatisfactionGuarantee';
// import PriceMatch from './PriceMatch';
// import Bone from './Bone';
// import QuestionMark from './QuestionMark';
// import Tooth from './Tooth';
// import Eye from './Eye';
// import Cardiology from './Cardiology';
// import PlasticSurgery from './PlasticSurgery';
// import Gender from './Gender';
// import Urology from './Urology';
// import Balance from './Balance';
// import FaceTreatments from './FaceTreatments';
// import Oncology from './Oncology';
import Schedule from './Schedule';
// import VerifiedAlternate from './VerifiedAlternate';
// import VerifiedAlternateFilled from './VerifiedAlternateFilled';
import Search from './Search';
// import House from './House';
// import ServiceFeePriceTag from './ServiceFeePriceTag';
// import Quno from './Quno';
// import Headset from './Headset';
// import Shield from './Shield';
// import Airplane from './Airplane';
// import Suitcase from './Suitcase';
// import VerifiedClinic from './VerifiedClinic';
// import Languages from './Languages';
// import LocationPinAlternate from './LocationPinAlternate';
import Info from './Info';
// import Success from './Success';
import ErrorIcon from './ErrorIcon';
import Currency from './Currency';
// import Thumb from './Thumb';
import Visa from './Visa';
// import Neurology from './Neurology';
// import Bell from './Bell';
// import Document from './Document';
// import HouseAlternate from './HouseAlternate';
import Gear from './Gear';
// import Quote from './Quote';
// import User from './User';
// import Logout from './Logout';
// import ChatBubbleMessage from './ChatBubbleMessage';
// import Envelope from './Envelope';
// import WhatsappAlternate from './WhatsappAlternate';
import FiltersAlternate from './FiltersAlternate';
// import Copy from './Copy';
// import Lock from './Lock';
// import LowestPriceGuarantee from './LowestPriceGuarantee';
import Plus from './Plus';
import SuccessAlternate from './SuccessAlternate';
import Download from './Download';
import Chevron from './Chevron';
import Plane from './Plane';
import ClinicDoctorStay from './ClinicDoctorStay';
import Reviews from './Reviews';
import FaqQuestionMark from './FaqQuestionMark';
import Pdf from './Pdf';
import PaperClip from './PaperClip';
import Calendar from './Calendar';
import Badge from './Badge';
import CreditCard from './Payment/CreditCard';
import type { NestedIconMap } from '@quno/patient-journey/src/components/ui/Icon';

export const Icons: NestedIconMap = {
  Arrow,
  Angle,
  Email,
  Phone,
  Menu,
  Cross,
  Star,
  // PhoneLines,
  Globe,
  // Whatsapp,
  // Facebook,
  // Instagram,
  // LinkedIn,
  // Twitter,
  // Youtube,
  Xmark,
  // Verified,
  Countries,
  Payment,
  ArrowShort,
  // Qunomedical,
  // Messenger,
  // Heart,
  Check,
  // RoundCheck,
  LocationPin,
  // Filters,
  USUKFlag,
  // Free,
  Stethoscope,
  // SatisfactionGuarantee,
  // PriceMatch,
  // Bone,
  // QuestionMark,
  // Tooth,
  // Eye,
  // Cardiology,
  // PlasticSurgery,
  // Gender,
  // Urology,
  // Balance,
  // FaceTreatments,
  // Oncology,
  Schedule,
  // VerifiedAlternate,
  // VerifiedAlternateFilled,
  Search,
  // House,
  // ServiceFeePriceTag,
  // Quno,
  // Headset,
  // Shield,
  // Airplane,
  // Suitcase,
  // VerifiedClinic,
  // Languages,
  // LocationPinAlternate,
  Info,
  // Success,
  ErrorIcon,
  Currency,
  // Thumb,
  Visa,
  // Neurology,
  // Bell,
  // Document,
  // HouseAlternate,
  Gear,
  // Quote,
  // User,
  // Logout,
  // ChatBubbleMessage,
  // Envelope,
  // WhatsappAlternate,
  FiltersAlternate,
  // Copy,
  // Lock,
  // LowestPriceGuarantee,
  Plus,
  SuccessAlternate,
  Download,
  Chevron,
  // Plane,
  // ClinicDoctorStay,
  Reviews,
  FaqQuestionMark,
  Pdf,
  PaperClip,
  Calendar,
  Badge,
  CreditCard,
  Plane,
  ClinicDoctorStay,
};
